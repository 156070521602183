import SuperTokens from "supertokens-web-js"
import Passwordless from "supertokens-web-js/recipe/passwordless"
import Session from "supertokens-web-js/recipe/session"
import ThirdPartyPasswordless from "supertokens-web-js/recipe/thirdpartypasswordless"

SuperTokens.init({
  appInfo: {
    appName: "fe-app",
    apiDomain: window.origin,
    apiBasePath: "/api/auth",
  },
  recipeList: [Session.init(), Passwordless.init(), ThirdPartyPasswordless.init()],
})
