import type { RouterHistory } from "@tanstack/react-router"
import { Outlet, RootRoute, Route, Router, redirect } from "@tanstack/react-router"
import { lazy } from "react"

import { Config } from "#config-fe"
import { a404Route } from "#pages/404/index.js"
import { dashboardRoute } from "#pages/dashboard/index.js"
import {
  loginGoogleRoute,
  loginIndexRoute,
  loginMappingRoute,
  loginMappingSuccessRoute,
  loginSuccessRoute,
  loginVerifyRoute,
} from "#pages/login/index.js"
import { loginInviteRoute } from "#pages/login/invite/index.js"
import {
  onboardingIndexRoute,
  onboardingJoinRoute,
  onboardingMembersRoute,
  onboardingOrganizationRoute,
  onboardingSuccessRoute,
  onboardingWelcomeRoute,
} from "#pages/onboarding/index.js"
import {
  settingsAppearanceRoute,
  settingsBillingPlanRoute,
  settingsBillingRoute,
  settingsMembersRoute,
  settingsNotificationsRoute,
  settingsProfileRoute,
  settingsRoute,
} from "#pages/settings/index.js"
import { subscriptionRoute } from "#pages/subscription/index.js"
import { useUserInfo } from "#store/user-info.js"

export const shouldNeverHappen = (_: never) => {
  return new Error("Should never happen")
}

const TanStackRouterDevtools =
  Config.VITE_ENVIRONMENT !== "local"
    ? () => null // Render nothing in production
    : lazy(() =>
        // Lazy load in local development
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        })),
      )

// Create a root route
export const rootRoute = new RootRoute({
  component: () => (
    <>
      <Outlet />
      <TanStackRouterDevtools />
    </>
  ),
})

// This function, based on the status of the user will try to figure the best URL to redirect user to.
const getBestRedirectUrl = () => {
  const userInfo = useUserInfo.getState()
  if (userInfo.state === "Init") {
    throw new Error("User info is not initialized, this should never happen")
  }

  if (userInfo.state === "NotAuthenticated") {
    throw redirect({
      to: "/login",
      search: {
        // Use the current location to power a redirect after login
        // (Do not use `router.state.resolvedLocation` as it can
        // potentially lag behind the actual current location)
        redirect: window.location.href,
      },
    })
  }

  if (userInfo.state === "Onboarded") {
    throw redirect({
      to: `/$slug/dashboard`,
      params: {
        slug: userInfo.currentOrg.slug,
      },
    })
  }
  if (userInfo.state === "NotOnboarded") {
    throw redirect({
      to: "/onboarding",
    })
  }
  throw shouldNeverHappen(userInfo)
}

// Create an index route
export const indexRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/",
  beforeLoad: () => {
    const { pathname } = window.location
    const userInfo = useUserInfo.getState()

    // Not a root dir, so render whatever the page and rest of the router will handle it if needed
    if (pathname !== "/") {
      return undefined
    }
    if (userInfo.state === "NotAuthenticated") {
      throw redirect({
        to: "/login",
        search: {
          // Use the current location to power a redirect after login
          // (Do not use `router.state.resolvedLocation` as it can
          // potentially lag behind the actual current location)
          redirect: pathname !== "/" ? window.location.href : undefined,
        },
      })
    }
    return getBestRedirectUrl()
  },
})

export const onboardingRoute = new Route({
  getParentRoute: () => authenticatedRoute,
  path: "/onboarding",
})

export const loginRoute = new Route({
  getParentRoute: () => indexRoute,
  path: "/login",
  validateSearch: (search) => {
    if ("redirect" in search) {
      return { redirect: search.redirect as string }
    }
    return {}
  },
  beforeLoad: () => {
    const userInfo = useUserInfo.getState()
    if (userInfo.state === "NotAuthenticated") {
      return undefined
    }
    return getBestRedirectUrl()
  },
})

export const sluggedRoute = new Route({
  getParentRoute: () => onboardedGuardRoute,
  path: "/$slug",
})

const dashboardWrongRoute = new Route({
  getParentRoute: () => onboardedGuardRoute,
  path: "/dashboard",
  beforeLoad: () => {
    const userInfo = useUserInfo.getState()
    if (userInfo.state === "Onboarded") {
      throw redirect({
        to: `/$slug/dashboard`,
        params: {
          slug: userInfo.currentOrg.slug,
        },
      })
    }
    getBestRedirectUrl()
  },
})

export const authenticatedRoute = new Route({
  getParentRoute: () => rootRoute,
  id: "authenticated-route",
  beforeLoad: () => {
    const userInfo = useUserInfo.getState()
    if (userInfo.state === "NotAuthenticated") {
      return getBestRedirectUrl()
    }
    return undefined
  },
})
export const onboardedGuardRoute = new Route({
  getParentRoute: () => authenticatedRoute,
  id: "onboarded-route",
  beforeLoad: () => {
    const userInfo = useUserInfo.getState()
    if (userInfo.state === "Onboarded") {
      return undefined
    }
    return getBestRedirectUrl()
  },
})

// Create the route tree using your routes
const routeTree = rootRoute.addChildren([
  a404Route,
  indexRoute.addChildren([
    subscriptionRoute,
    loginMappingRoute,
    loginMappingSuccessRoute,
    loginRoute.addChildren([
      loginIndexRoute,
      loginVerifyRoute,
      loginGoogleRoute,
      loginInviteRoute,
      loginSuccessRoute,
    ]),
    authenticatedRoute.addChildren([
      onboardingJoinRoute,
      onboardingRoute.addChildren([
        onboardingIndexRoute,
        onboardingWelcomeRoute,
        onboardingOrganizationRoute,
        onboardingMembersRoute,
        onboardingSuccessRoute,
      ]),
      onboardedGuardRoute.addChildren([
        dashboardWrongRoute,
        sluggedRoute.addChildren([
          dashboardRoute,
          settingsRoute.addChildren([
            settingsProfileRoute,
            settingsAppearanceRoute,
            settingsNotificationsRoute,
            settingsMembersRoute,
            settingsBillingPlanRoute,
            settingsBillingRoute,
          ]),
        ]),
      ]),
    ]),
  ]),
])

// Create the router using your route tree
export const createRouter = (params?: { history?: RouterHistory }) =>
  new Router({ routeTree, defaultPreload: "intent", history: params?.history })

// Register your router for maximum type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: ReturnType<typeof createRouter>
  }
}
