import type { ColumnDef } from "@tanstack/react-table"
import {
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  useReactTable,
} from "@tanstack/react-table"
import React from "react"

import { Table, TableBody, TableCell, TableRow } from "@fourel/ui"

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
}
export function OrganizationUsersTable<TData, TValue>({
  columns,
  data,
}: DataTableProps<TData, TValue>) {
  const table = useReactTable({
    data,
    columns,
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  })

  return (
    <Table>
      <TableBody className="p-2">
        {table.getRowModel().rows?.map((row) => (
          <TableRow key={row.id} className="flex justify-between">
            {row.getVisibleCells().map((cell) => (
              <TableCell
                key={cell.id}
                width={cell.column.id === "name" ? "40%" : "10%"}
                align="justify"
                className="flex justify-start align-middle"
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
