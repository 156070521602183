import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import PlusIcon from "lucide-static/icons/plus.svg"
import Delete from "lucide-static/icons/x.svg"
import { useFieldArray, useForm } from "react-hook-form"
import InlineSVG from "react-inlinesvg"
import * as z from "zod"

import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DropdownMenuItem,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Label,
  ScrollArea,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
  ToastAction,
  cn,
  useToast,
} from "@fourel/ui"

import { graphql } from "#gql"
import type { MutationSendInviteInput } from "#gql/graphql"
import { client } from "#graphql-client.js"
import { useOnboardedUserInfo } from "#store/user-info.js"

const member = z.object({
  members: z.array(
    z.object({
      email: z.literal("").or(z.string().email().min(7)),
      role: z.literal("").or(
        z.string().refine((val) => val !== "", {
          message: "Please select a role.",
        }),
      ),
    }),
  ),
})

type Member = z.infer<typeof member>

const SendInviteDocument = graphql(/* GraphQL */ `
  mutation SendInvite($input: MutationSendInviteInput!) {
    sendInvite(input: $input) {
      __typename
    }
  }
`)

export const AddMember = ({ asMenu, open }: { asMenu?: boolean; open: boolean }) => {
  const queryClient = useQueryClient()
  const { currentOrg } = useOnboardedUserInfo()
  const { toast } = useToast()
  const form = useForm<Member>({
    resolver: zodResolver(member),
    defaultValues: {
      members: [
        {
          email: "",
          role: "",
        },
        {
          email: "",
          role: "",
        },
        {
          email: "",
          role: "",
        },
      ],
    },
    reValidateMode: "onChange",
  })
  const { fields, append, remove } = useFieldArray({
    name: "members",
    control: form.control,
  })

  const { mutate } = useMutation({
    mutationKey: ["sendInvite"],
    mutationFn: (input: MutationSendInviteInput) =>
      client.request(SendInviteDocument, { input }),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ["OrganizationInvites"] })
      form.reset()
      toast({
        title: "Success!",
        description: "Invites have been sent.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
    onError: () => {
      toast({
        variant: "destructive",
        title: "Error",
        description: "User is already a member of this organization.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
  })

  const handleEmailOnBlur = (i: number) => {
    const email = form.getValues(`members.${i}.email`)
    const role = form.getValues(`members.${i}.role`)
    if (email && !role) {
      form.setError(`members.${i}.role`, {
        type: "manual",
        message: "Please select a role.",
      })
    }
  }
  const onSubmit = (data: z.infer<typeof member>) => {
    const validMembers = data.members.filter((it) => it.email !== "" && it.role !== "")
    if (validMembers.length === 0) {
      toast({
        title: "Info",
        description: "Please fill out at least one member.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    } else {
      mutate({ organizationId: currentOrg.id, invites: validMembers })
    }
  }
  return (
    <Dialog>
      <DialogTrigger className="w-full" asChild>
        {asMenu ? (
          <DropdownMenuItem>
            <InlineSVG src={PlusIcon} className="ml-[-4px] mr-[7px] h-5 w-5" />
            <span className={cn("whitespace-nowrap", !open && "hidden text-[0px]")}>
              Invite people
            </span>
          </DropdownMenuItem>
        ) : (
          <span className="flex items-center gap-2">
            <InlineSVG src={PlusIcon} className="h-5 w-5 min-w-5" />
            <span className={cn("whitespace-nowrap", !open && "hidden text-[0px]")}>
              Invite people
            </span>
          </span>
        )}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add new members</DialogTitle>
          <DialogDescription>
            Add a new member to your organization and assign a role.
          </DialogDescription>
        </DialogHeader>
        <div>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="mt-1 flex w-full items-center justify-center gap-4">
                <ScrollArea className="h-[260px] w-full">
                  {fields.map((memberField, i) => (
                    <div key={memberField.id} className="p-2">
                      <Label>Member {i + 1}</Label>
                      <div className="flex gap-4">
                        <FormField
                          name={`members.${i}.email`}
                          control={form.control}
                          render={({ field }) => (
                            <FormItem className="space-y-0">
                              <FormControl>
                                <Input
                                  placeholder="Email@example.com"
                                  {...field}
                                  onBlur={() => handleEmailOnBlur(i)}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name={`members.${i}.role`}
                          render={({ field }) => (
                            <FormItem
                              className="w-[30%] space-y-0"
                              data-testid="invite-member-role-select-container"
                            >
                              <FormControl>
                                <Select
                                  value={field.value || undefined}
                                  onValueChange={(val) => {
                                    field.onChange(val)
                                    form.clearErrors()
                                  }}
                                >
                                  <SelectTrigger>
                                    <SelectValue placeholder="Role" {...field} />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectGroup>
                                      <SelectLabel>Role</SelectLabel>
                                      <SelectItem value="owner">Owner</SelectItem>
                                      <SelectItem value="admin">Admin</SelectItem>
                                      <SelectItem value="member">Member</SelectItem>
                                    </SelectGroup>
                                  </SelectContent>
                                </Select>
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <Button
                          onClick={() => remove(i)}
                          variant="ghost"
                          data-testid="invite-people-delete-member-btn"
                        >
                          <InlineSVG src={Delete} className="h-[16px]" />
                        </Button>
                      </div>
                    </div>
                  ))}
                </ScrollArea>
              </div>
              <Button
                className="ml-2 mt-1"
                type="button"
                variant="outline"
                onClick={() => append({ email: "", role: "" })}
              >
                Add more members
              </Button>
              <DialogTrigger
                type="submit"
                className="mt-6 w-full"
                asChild
                disabled={!form.formState.isValid}
              >
                <Button>Invite members</Button>
              </DialogTrigger>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  )
}
