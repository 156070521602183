import { Route, lazyRouteComponent } from "@tanstack/react-router"

import { onboardingIndexRoute } from "../index.js"

export const onboardingOrganizationRoute = new Route({
  getParentRoute: () => onboardingIndexRoute,
  path: "/organization",
  component: lazyRouteComponent(
    () => import("./onboarding-organization.js"),
    "Organization",
  ),
})
