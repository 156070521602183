import type { GraphQLError } from "graphql/error"
import { doesSessionExist } from "supertokens-web-js/recipe/session"
import type { Simplify, TaggedUnion } from "type-fest"
import { shallow } from "zustand/shallow"
import { createWithEqualityFn } from "zustand/traditional"

import type {
  FetchUserInfo_MeNotOnboardedFragment,
  FetchUserInfo_MeOnboardedFragment,
  FetchUserInfo_OrganizationFragment,
} from "#gql/graphql.js"
import { getFragmentData, graphql } from "#gql/index.js"
import { client } from "#graphql-client.js"
import "#services/supertokens.js"

export const FetchUserInfo_OrganizationFragmentDocument = graphql(/* GraphQL */ `
  fragment fetchUserInfo_Organization on Organization {
    id
    slug
    name
  }
`)

const FetchUserInfo_MeOnboardedFragmentDocument = graphql(/* GraphQL */ `
  fragment fetchUserInfo_MeOnboarded on MeOnboarded {
    user {
      id
      email
      name
      organizations {
        ...fetchUserInfo_Organization
      }
    }
  }
`)

const FetchUserInfo_MeNotOnboardedFragmentDocument = graphql(/* GraphQL */ `
  fragment fetchUserInfo_MeNotOnboarded on MeNotOnboarded {
    isOnboarded
  }
`)

const MeQuery = graphql(/* GraphQL */ `
  query fetchUserInfo_Me {
    me {
      __typename
      ... on MeOnboarded {
        ...fetchUserInfo_MeOnboarded
      }
      ... on MeNotOnboarded {
        ...fetchUserInfo_MeNotOnboarded
      }
    }
  }
`)

const fetchUserInfo = async () => {
  if (await doesSessionExist()) {
    return client.request(MeQuery).catch((data: unknown) => {
      if (data instanceof Object && "request" in data && "response" in data) {
        // @ts-expect-error graphql-request doesn't export types, so we hack around it
        const errors = data.response?.errors as GraphQLError[] | undefined
        if (errors?.[0].extensions?.code === "UNAUTHORIZED") {
          return null
        }
      }
      throw data
    })
  }
  return null
}

type TaggedWithDefault<
  Tag extends string,
  Fields extends Record<string, unknown>,
  Default extends Record<string, unknown>,
> = Simplify<
  TaggedUnion<
    Tag,
    {
      [Property in keyof Fields]: Fields[Property] & Default
    }
  >
>

type ExtractAndOmitMeStore<U extends MeStore["state"]> = Omit<
  Extract<
    MeStore,
    {
      state: U
    }
  >,
  "selectOrganization" | "fetchUserInfo"
>

type MeStore = TaggedWithDefault<
  "state",
  {
    Onboarded: {
      me: FetchUserInfo_MeOnboardedFragment
      currentOrg: FetchUserInfo_OrganizationFragment
    }
    NotOnboarded: { me: FetchUserInfo_MeNotOnboardedFragment }
    NotAuthenticated: { me: null }
    Init: { me: null }
  },
  {
    fetchUserInfo: () => Promise<
      | ExtractAndOmitMeStore<"Onboarded">
      | ExtractAndOmitMeStore<"NotOnboarded">
      | ExtractAndOmitMeStore<"NotAuthenticated">
    >
    selectOrganization: (slug: string) => void
  }
>

export const useUserInfo = createWithEqualityFn<MeStore>((set) => {
  const previouslySelectedSlug = localStorage.getItem("fourel_previously_selected_slug")
  return {
    state: "Init",
    me: null,
    selectOrganization: (slug: string) => {
      localStorage.setItem("fourel_previously_selected_slug", slug)
      set((prev) => {
        if (prev.state === "Onboarded") {
          return {
            currentOrg: getFragmentData(
              FetchUserInfo_OrganizationFragmentDocument,
              prev.me.user.organizations,
            ).find((org) => org.slug === slug),
          }
        }
        throw new Error("Cannot select org when not onboarded")
      })
    },
    fetchUserInfo: async () => {
      const data = await fetchUserInfo()
      const calculateState = () => {
        switch (data?.me?.__typename) {
          case "MeOnboarded": {
            const me = getFragmentData(FetchUserInfo_MeOnboardedFragmentDocument, data.me)
            const organizations = getFragmentData(
              FetchUserInfo_OrganizationFragmentDocument,
              me.user.organizations,
            )
            const currentOrg =
              organizations.find((org) => org.slug === previouslySelectedSlug) ||
              organizations[0]
            return {
              currentOrg,
              state: "Onboarded",
              me,
            } as const
          }
          case "MeNotOnboarded":
            return {
              state: "NotOnboarded",
              me: getFragmentData(FetchUserInfo_MeNotOnboardedFragmentDocument, data.me),
            } as const
          default:
            return {
              state: "NotAuthenticated",
              me: null,
            } as const
        }
      }
      const state = calculateState()
      set(state)
      return state
    },
  }
}, shallow)

export const useOnboardedUserInfo = () => {
  const userInfo = useUserInfo()
  if (userInfo.state === "Onboarded") {
    return userInfo
  }
  throw new Error(
    "User is not onboarded, you should only run this hook inside components that are suppose to be ran in onboarded context",
  )
}
