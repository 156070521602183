import { z } from "zod"

export const frontendAvailableConfigSchema = z.object({
  MODE: z.enum(["development", "test", "production"]),
  VITE_ENVIRONMENT: z.enum(["local", "staging", "test", "production"]),
  VITE_GOOGLE_OAUTH_REDIRECT_URL: z.string(),
  VITE_SENTRY_AUTH_TOKEN: z.string().optional(),
  VITE_FC_GIT_COMMIT_SHA: z.string().optional(),
})

export const configSchema = z
  .object({
    API_URL: z.string(),

    FC_GIT_COMMIT_SHA: z.string().optional(),

    SENTRY_AUTH_TOKEN: z.string().optional(),
    PORT: z.coerce.number().optional(),
  })
  .merge(frontendAvailableConfigSchema)

export const validateConfig = (env: Record<string, string | undefined>) => {
  try {
    return configSchema.parse(env)
  } catch (error) {
    const printError = (err: z.ZodError) => {
      console.error(
        "Environment variables validation failed. App is misconfigured. Please see more info below:\n",
      )
      err.errors.forEach((anError) => {
        console.error(`${anError.path.join(".")}: ${anError.message}`)
      })
    }

    if (error instanceof z.ZodError) {
      printError(error)
      // eslint-disable-next-line no-throw-literal
      throw "Wrong configuration, see info above this message"
    } else {
      throw error
    }
  }
}

export const validateFrontendConfig = (env: Record<string, string | undefined>) => {
  try {
    return frontendAvailableConfigSchema.parse(env)
  } catch (error) {
    const printError = (err: z.ZodError) => {
      console.error(
        "Environment variables validation failed. App is misconfigured. Please see more info below:\n",
      )
      err.errors.forEach((anError) => {
        console.error(`${anError.path.join(".")}: ${anError.message}`)
      })
    }

    if (error instanceof z.ZodError) {
      printError(error)
      // eslint-disable-next-line no-throw-literal
      throw "Wrong configuration, see info above this message"
    } else {
      throw error
    }
  }
}
