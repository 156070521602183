import { Link, Route } from "@tanstack/react-router"
import RightIcon from "lucide-static/icons/arrow-right.svg"
import InlineSVG from "react-inlinesvg"

import { Separator, Table, TableBody, TableCell, TableHeader, TableRow } from "@fourel/ui"

import { settingsRoute } from "#pages/settings/settings-route.js"

const tableData = [
  {
    date: "2021-08-01",
    description: "Start Plan",
    invoiceTotal: "359.00 USD",
    status: "Paid",
    invoice: "view",
  },
  {
    date: "2021-08-02",
    description: "Start Plan",
    invoiceTotal: "359.00 USD",
    status: "Paid",
    invoice: "view",
  },
  {
    date: "2021-08-03",
    description: "Start Plan",
    invoiceTotal: "359.00 USD",
    status: "Paid",
    invoice: "view",
  },
  {
    date: "2021-08-04",
    description: "Start Plan",
    invoiceTotal: "359.00 USD",
    status: "Paid",
    invoice: "view",
  },
  {
    date: "2021-08-05",
    description: "Start Plan",
    invoiceTotal: "359.00 USD",
    status: "Paid",
    invoice: "view",
  },
  {
    date: "2021-08-06",
    description: "Start Plan",
    invoiceTotal: "359.00 USD",
    status: "Paid",
    invoice: "view",
  },
]

const tableHeaders = ["Date", "Description", "Invoice Total", "Status", "Invoice"]

function SettingsBilling() {
  return (
    <div className="w-screen max-w-[735px]">
      <div className="mb-6 flex flex-col">
        <span className="dark:text-foreground pb-1 text-lg font-semibold text-slate-900">
          Billing
        </span>
        <span className="dark:text-muted-foreground pb-6 text-sm text-slate-600">
          Managing your billing information and invoices.
        </span>
        <Separator />
      </div>
      <div className="mb-12 flex flex-col gap-6">
        <span className="dark:text-foreground text-sm font-medium text-slate-900">
          Current plan
        </span>
        <p className="dark:text-muted-foreground text-sm font-normal text-slate-600">
          You are currently on Start Plan. You can update plan and have more functions.
        </p>
        <Link
          from="/$slug"
          to="settings/billing/plans"
          className="hover:bg-accent hover:text-accent-foreground dark:text-foreground flex w-max items-center gap-x-2.5 rounded p-2  text-sm font-medium text-slate-900"
        >
          <span>View plans and upgrade</span>
          <InlineSVG src={RightIcon} className="h-4 w-4" />
        </Link>
        <Separator />
      </div>
      <Table className="m-auto min-w-[300px] max-w-[900px] p-10">
        <TableHeader className="text-slate-500">
          <TableRow>
            {tableHeaders.map((header) => (
              <TableCell key={header} className="pl-0">
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody className="">
          {tableData.map((data) => (
            <TableRow key={data.date}>
              <TableCell className="pl-0">{data.date}</TableCell>
              <TableCell className="pl-0">{data.description}</TableCell>
              <TableCell className="pl-0">{data.invoiceTotal}</TableCell>
              <TableCell className="pl-0">{data.status}</TableCell>
              <TableCell className="pl-0">{data.invoice}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export const settingsBillingRoute = new Route({
  getParentRoute: () => settingsRoute,
  path: "billing",
  component: SettingsBilling,
})
