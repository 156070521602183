/* eslint-disable */
import * as types from './graphql.js';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation SendInvite($input: MutationSendInviteInput!) {\n    sendInvite(input: $input) {\n      __typename\n    }\n  }\n": types.SendInviteDocument,
    "\n  query IsAvailableOrganizationSlug($slug: String!) {\n    isAvailableOrganizationSlug(slug: $slug)\n  }\n": types.IsAvailableOrganizationSlugDocument,
    "\n  mutation AddNewOrganization($input: MutationAddNewOrganizationInput!) {\n    addNewOrganization(input: $input) {\n      id\n      name\n      slug\n    }\n  }\n": types.AddNewOrganizationDocument,
    "\n  query HasSimilarIdentity {\n    hasSimilarIdentity {\n      userId\n    }\n  }\n": types.HasSimilarIdentityDocument,
    "\n  query InviteInfo($input: QueryInviteInfoInput!) {\n    inviteInfo(input: $input) {\n      id\n      organizationName\n      organizationSlug\n      inviterName\n    }\n  }\n": types.InviteInfoDocument,
    "\n  query AddMappingRequest {\n    addMappingRequest\n  }\n": types.AddMappingRequestDocument,
    "\n  mutation MapIdentity($input: MutationMapIdentityInput!) {\n    mapIdentity(input: $input) {\n      userId\n    }\n  }\n": types.MapIdentityDocument,
    "\n  mutation JoinOrganization($input: MutationJoinOrganizationInput!) {\n    joinOrganization(input: $input) {\n      __typename\n    }\n  }\n": types.JoinOrganizationDocument,
    "\n  mutation OnboardUserAndOrganization($input: MutationOnboardUserAndOrganizationInput!) {\n    onboardUserAndOrganization(input: $input) {\n      __typename\n    }\n  }\n": types.OnboardUserAndOrganizationDocument,
    "\n  mutation OnboardUserWithInvite($input: MutationOnboardUserWithInviteInput!) {\n    onboardUserWithInvite(input: $input) {\n      __typename\n    }\n  }\n": types.OnboardUserWithInviteDocument,
    "\n  query InvitesByOrganizationId($input: QueryInviteByOrganizationIdInput!) {\n    inviteByOrganizationId(input: $input) {\n      id\n      email\n      role\n    }\n  }\n": types.InvitesByOrganizationIdDocument,
    "\n  mutation DeleteUser($input: MutationDeleteUserInput!) {\n    deleteUser(input: $input)\n  }\n": types.DeleteUserDocument,
    "\n  mutation UpdateUserRole($input: MutationUpdateUserRoleInput!) {\n    updateUserRole(input: $input)\n  }\n": types.UpdateUserRoleDocument,
    "\n  query OrganizationUsers($input: QueryOrganizationUsersInput!) {\n    organizationUsers(input: $input) {\n      id\n      name\n      email\n      role\n    }\n  }\n": types.OrganizationUsersDocument,
    "\n  fragment fetchUserInfo_Organization on Organization {\n    id\n    slug\n    name\n  }\n": types.FetchUserInfo_OrganizationFragmentDoc,
    "\n  fragment fetchUserInfo_MeOnboarded on MeOnboarded {\n    user {\n      id\n      email\n      name\n      organizations {\n        ...fetchUserInfo_Organization\n      }\n    }\n  }\n": types.FetchUserInfo_MeOnboardedFragmentDoc,
    "\n  fragment fetchUserInfo_MeNotOnboarded on MeNotOnboarded {\n    isOnboarded\n  }\n": types.FetchUserInfo_MeNotOnboardedFragmentDoc,
    "\n  query fetchUserInfo_Me {\n    me {\n      __typename\n      ... on MeOnboarded {\n        ...fetchUserInfo_MeOnboarded\n      }\n      ... on MeNotOnboarded {\n        ...fetchUserInfo_MeNotOnboarded\n      }\n    }\n  }\n": types.FetchUserInfo_MeDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SendInvite($input: MutationSendInviteInput!) {\n    sendInvite(input: $input) {\n      __typename\n    }\n  }\n"): (typeof documents)["\n  mutation SendInvite($input: MutationSendInviteInput!) {\n    sendInvite(input: $input) {\n      __typename\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query IsAvailableOrganizationSlug($slug: String!) {\n    isAvailableOrganizationSlug(slug: $slug)\n  }\n"): (typeof documents)["\n  query IsAvailableOrganizationSlug($slug: String!) {\n    isAvailableOrganizationSlug(slug: $slug)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AddNewOrganization($input: MutationAddNewOrganizationInput!) {\n    addNewOrganization(input: $input) {\n      id\n      name\n      slug\n    }\n  }\n"): (typeof documents)["\n  mutation AddNewOrganization($input: MutationAddNewOrganizationInput!) {\n    addNewOrganization(input: $input) {\n      id\n      name\n      slug\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query HasSimilarIdentity {\n    hasSimilarIdentity {\n      userId\n    }\n  }\n"): (typeof documents)["\n  query HasSimilarIdentity {\n    hasSimilarIdentity {\n      userId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query InviteInfo($input: QueryInviteInfoInput!) {\n    inviteInfo(input: $input) {\n      id\n      organizationName\n      organizationSlug\n      inviterName\n    }\n  }\n"): (typeof documents)["\n  query InviteInfo($input: QueryInviteInfoInput!) {\n    inviteInfo(input: $input) {\n      id\n      organizationName\n      organizationSlug\n      inviterName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AddMappingRequest {\n    addMappingRequest\n  }\n"): (typeof documents)["\n  query AddMappingRequest {\n    addMappingRequest\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation MapIdentity($input: MutationMapIdentityInput!) {\n    mapIdentity(input: $input) {\n      userId\n    }\n  }\n"): (typeof documents)["\n  mutation MapIdentity($input: MutationMapIdentityInput!) {\n    mapIdentity(input: $input) {\n      userId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation JoinOrganization($input: MutationJoinOrganizationInput!) {\n    joinOrganization(input: $input) {\n      __typename\n    }\n  }\n"): (typeof documents)["\n  mutation JoinOrganization($input: MutationJoinOrganizationInput!) {\n    joinOrganization(input: $input) {\n      __typename\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation OnboardUserAndOrganization($input: MutationOnboardUserAndOrganizationInput!) {\n    onboardUserAndOrganization(input: $input) {\n      __typename\n    }\n  }\n"): (typeof documents)["\n  mutation OnboardUserAndOrganization($input: MutationOnboardUserAndOrganizationInput!) {\n    onboardUserAndOrganization(input: $input) {\n      __typename\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation OnboardUserWithInvite($input: MutationOnboardUserWithInviteInput!) {\n    onboardUserWithInvite(input: $input) {\n      __typename\n    }\n  }\n"): (typeof documents)["\n  mutation OnboardUserWithInvite($input: MutationOnboardUserWithInviteInput!) {\n    onboardUserWithInvite(input: $input) {\n      __typename\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query InvitesByOrganizationId($input: QueryInviteByOrganizationIdInput!) {\n    inviteByOrganizationId(input: $input) {\n      id\n      email\n      role\n    }\n  }\n"): (typeof documents)["\n  query InvitesByOrganizationId($input: QueryInviteByOrganizationIdInput!) {\n    inviteByOrganizationId(input: $input) {\n      id\n      email\n      role\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteUser($input: MutationDeleteUserInput!) {\n    deleteUser(input: $input)\n  }\n"): (typeof documents)["\n  mutation DeleteUser($input: MutationDeleteUserInput!) {\n    deleteUser(input: $input)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateUserRole($input: MutationUpdateUserRoleInput!) {\n    updateUserRole(input: $input)\n  }\n"): (typeof documents)["\n  mutation UpdateUserRole($input: MutationUpdateUserRoleInput!) {\n    updateUserRole(input: $input)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OrganizationUsers($input: QueryOrganizationUsersInput!) {\n    organizationUsers(input: $input) {\n      id\n      name\n      email\n      role\n    }\n  }\n"): (typeof documents)["\n  query OrganizationUsers($input: QueryOrganizationUsersInput!) {\n    organizationUsers(input: $input) {\n      id\n      name\n      email\n      role\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment fetchUserInfo_Organization on Organization {\n    id\n    slug\n    name\n  }\n"): (typeof documents)["\n  fragment fetchUserInfo_Organization on Organization {\n    id\n    slug\n    name\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment fetchUserInfo_MeOnboarded on MeOnboarded {\n    user {\n      id\n      email\n      name\n      organizations {\n        ...fetchUserInfo_Organization\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment fetchUserInfo_MeOnboarded on MeOnboarded {\n    user {\n      id\n      email\n      name\n      organizations {\n        ...fetchUserInfo_Organization\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment fetchUserInfo_MeNotOnboarded on MeNotOnboarded {\n    isOnboarded\n  }\n"): (typeof documents)["\n  fragment fetchUserInfo_MeNotOnboarded on MeNotOnboarded {\n    isOnboarded\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query fetchUserInfo_Me {\n    me {\n      __typename\n      ... on MeOnboarded {\n        ...fetchUserInfo_MeOnboarded\n      }\n      ... on MeNotOnboarded {\n        ...fetchUserInfo_MeNotOnboarded\n      }\n    }\n  }\n"): (typeof documents)["\n  query fetchUserInfo_Me {\n    me {\n      __typename\n      ... on MeOnboarded {\n        ...fetchUserInfo_MeOnboarded\n      }\n      ... on MeNotOnboarded {\n        ...fetchUserInfo_MeNotOnboarded\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;